import { EndPoints } from '../Constants/Enums';
import Cookies from 'js-cookie';


async function getUserOrders() {
  try {
    const operationId = 6;
    const userID = JSON.parse( Cookies.get('userCookie')).uid;
    const requestBody = { operationId,userID };
    const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(EndPoints.getCategoryData, options);
      const jsonResponse = await response.json();
      return jsonResponse;
  } catch (error) {
    console.error('Error getting user orders :', error);
    throw error;
  }
}

export default getUserOrders;
