// Env: dev
// export const EndPoints = {
//   // AppOperations-dev
//   getCategoryData:
//     "https://mkhty36ce3c63j3dqq32zec5mu0wvtwb.lambda-url.us-east-2.on.aws/",
//   // mobileAppPN-dev
//   pushNotification:
//     "https://x3kod6lqo6yxptpxcxxrf3p2aa0buccg.lambda-url.us-east-2.on.aws/",
//   image: "https://res.cloudinary.com/dipqm0n1i/image/upload/EtihadAirways/",
// };

// export const bucketURL = "https://etihadairwaysassets143308-dev.s3.us-east-2.amazonaws.com/public/";

// Env: prod
export const EndPoints = {
  // AppOperations-prod
  getCategoryData:
    "https://qe5luavs5b4ydyscddsy4vcmci0kwhma.lambda-url.us-east-2.on.aws/",
  // mobileAppPN-prod
  pushNotification:
    "https://q4a7rg3uimxs3ew5jffef5mi3e0uefnb.lambda-url.us-east-2.on.aws/",
  image: "https://res.cloudinary.com/dipqm0n1i/image/upload/EtihadAirways/",
};
export const bucketURL =
  "https://etihadairwaysassetsd26b2-prod.s3.us-east-2.amazonaws.com/public/";

export const Pages = {
  MENU_LIST: "menuList",
  ORDERS: "myorders",
  ORDER_DETAILS: "orderdetails",
  TRACK_ORDER: "trackOrder",

  CART: "cart",
  LANGUAGES: "languages",
  REVIEW: "review",
  LANG: "lang",
};

export const Keys = {
  CONCEPT: "Concept",
  MAIN_CATEGORIES: "MainCategories",
  SUB_CATEGORIES: "SubCategories",
  CATEGORIES: "Categories",
  CART: "Cart",
  CONCEPTS: "Concepts",
};

export const OrderStatus = {
  pending: "pending",
  sent: "sent to restaurant",
  failed: "failed to sent to restaurant",
  confirmed: "confirmed",
  cancelled: "cancelled",
  pendingCancellation: "pending Cancellation",
  updated: "updated",
  acceptedByWaiter: "accepted by waiter",
  sentToKitchen: "sent To Kitchen",
  acceptedByKitchen: "accepted by kitchen",
  ready: "ready",
  allReady: "all ready",
  outForDelivery: "out for delivery",
  delivered: "delivered",
};

export const WaiterCallingStatus = {
  pending: "pending",
  arrived: "arrived",
};

export const OrderType = "Dine In";
export const MySecretKey = "MySecretKey";
