import "./Guard.css";
import { useEffect, useState } from "react";
import { Html5QrcodeScanType, Html5QrcodeScanner } from "html5-qrcode";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useApp from "../../hooks/useApp";
import { Typography } from "@mui/material";
// import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScanner";

export default function Guard() {
  const { showError } = useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [result, setResult] = useState("");

  var scanner;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    scanner = new Html5QrcodeScanner(
      "reader",
      {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
        // Only support camera scan type.
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      },
      (result) => handleScan(result),
      (error) => handleError(error)
    );
    scanner.render(handleScan, handleError);
  }, []);

  const handleScan = (result) => {
    console.log("Scanned Result:", result);
    setResult(result);
    scanner.clear();

    // navigate using ID
    const parts = result.split("/");
    if (parts.length > 0) {
      const encryptedID = parts[parts.length - 1];
      navigate(`/${encryptedID}`);
    } else {
      showError(" This QR Code is invalid. Please scan a valid one.");
    }
  };

  const handleError = (error) => {
    console.error("Error:", error);
  };

  return (
    <div className="guardContainer">
      <img
        alt="logo"
        src={require("../../assets/Etihad.png")}
        style={{
          width: 150,
          height: 100,
          borderRadius: 10,
          cursor: "pointer",
        }}
      />
      <div className="qrScanBody">
        <div id="reader" className="scanner-container"></div>
        <span style={{marginTop:"1rem"}} className="QRSpan">{t("ScanQR")}</span>
        <span style={{marginTop:"1rem"}}  className="QrParagraph">{t("ScanMessage")}</span>
      </div>
    </div>
  );
}
