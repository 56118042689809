import { EndPoints } from "../Constants/Enums";
import Cookies from 'js-cookie';

async function createCallWaiter(conceptID, tableID, tableName, count ,createdAt) {
    try {
      const operationId = 13;
      const userID = JSON.parse( Cookies.get('userCookie')).uid
      const requestBody = {
        operationId,
        userID: userID,
        conceptID,
        tableID,
        tableName,
        count: count,
        status: "pending",
        createdAt
      };
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(EndPoints.getCategoryData, options);
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      console.error("Error creating Cart :", error);
      throw error;
    }
  }
  
  export default createCallWaiter;
  