import React, { useState } from "react";
import "./ItemCard.css";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { EndPoints } from "../../Constants/Enums";
import { Typography ,Grid} from "@mui/material";
import { capitalizeSentence } from "../../helpers/utils";

function ItemCard({ item, Cart, canOrder , prepId ,handleOpenItem}) {
  const navigation = useNavigate();
  const { t, i18n } = useTranslation();
  const cart = useSelector((state) => state.cart.listing)
  const [imageNotFound, setImageNotFound] = useState(false)

  const price = item.prices && item.prices.length > 0 ? item.prices[0].price : 0;
  const handleClick =()=>{
    // if(!item.outOfStock)
    // {
    //   navigation('/orderdetails', { state: { item: item , Cart:cart, canOrder: canOrder, prepId:prepId } });
    // }
    handleOpenItem(item,cart,canOrder,prepId)
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <Grid container  className="ItemCardContainer" onClick={handleClick}>

      <Grid item xs={12} className="CardInfo">
      <div style={{ flex: 1, fontWeight: "600" ,color: 'black',fontSize: 18,fontFamily:  `etihadBold${i18n.language}`,wordWrap: 'break-word'}}>
       {capitalizeSentence(new Map(JSON.parse(item.multiLanguages))?.get(`${i18n.language}-${"name"}`)??item.name)}
        </div>
        <div className="Description " style={{fontFamily:`${i18n.language}"etihadBook"`}}>
        {capitalizeSentence(new Map(JSON.parse(item.multiLanguages))?.get(`${i18n.language}-${"description"}`)??item.description)}
          </div>
        <div style={{ flex: 1, color: "#997408",width:'100%',justifyContent:'flex-end',alignItems:'flex-end',textAlign:'right',marginTop:'0.5rem',fontWeight:'bold' }}>
         {price>0? `AED ${price}`: ''}
        </div>
        {(item?.choiceGroups[0]?.choices.length>0)&&( 
          <div>
        <div className="horizontal-line"></div>
        <div style={{color: '#919191', fontSize: 12, fontFamily: `etihadBook${i18n.language}`,  wordWrap: 'break-word'}}>{t("Extras")}:</div>
       <div style={{display:"flex"}} >{item?.choiceGroups[0]?.choices.map((choice,index)=>{
          return( <div key={index}  className="choices"  style={{fontFamily:`${i18n.language}"etihadBook"`,fontSize:15}}> 
          {(index===(item?.choiceGroups[0]?.choices.length-1))
          ?capitalizeSentence(new Map(JSON.parse(choice.multiLanguages))?.get(`${i18n.language}-${"name"}`)??choice.name)
          :capitalizeSentence(new Map(JSON.parse(choice.multiLanguages))?.get(`${i18n.language}-${"name"}`)??choice.name)+","} 
          </div>)
        })}
        </div>
        </div>
        )}
      </Grid>
    </Grid>
  );
}

export default ItemCard;
