import { Typography,Grid,Box } from "@mui/material";
import "./HomeTrackOrder.css";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { onUpdateOnlineOrder } from "../../graphql/subscriptions";
import { OrderStatus } from "../../Constants/Enums";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { formatTime } from "../../helpers/utils";
import { setCurrentOrder } from "../../store/ducks/app";
import { useDispatch, useSelector } from "react-redux";
import cookingIcon from "../../assets/cooking.png";
import { capitalizeSentence ,numberTranslation } from "../../helpers/utils";

export default function HomeTrackOrder({ order }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState("pending");

//  const currentOrder = useSelector((state) => state.app.currentOrder);
  function updateTrack(order) {
    switch (order.status) {
      case OrderStatus.pending: {
        setStatus("pending");
        break;
      }
      case OrderStatus.acceptedByWaiter : {
        setStatus("placed");
        break;
      }
      case OrderStatus.sentToKitchen: {
        setStatus("preparing");
        break;
      }
      case OrderStatus.allReady : {
      setStatus("ready");
      break;
      }
      case OrderStatus.delivered : {
        setStatus("delivered");
        break;
      }
      case OrderStatus.cancelled : {
        setStatus("cancelled");
        break;
      }
      default: {
        break;
      }
    }
  }

  useEffect(() => {
      // updateTrack(order);
      // Cookies.set("trackedOrder", JSON.stringify(order), { expires: 1 });
      const currentOrder = Cookies.get("trackedOrder")
    ? JSON.parse(Cookies.get("trackedOrder"))
    : null;
    
    updateTrack(currentOrder);
    const subscription = API.graphql({ query: onUpdateOnlineOrder });

    if ("subscribe" in subscription) {
      const sb = subscription.subscribe({
        next: (event) => {
          const updatedOrder = event.value.data.onUpdateOnlineOrder;
          console.log(updatedOrder)
          if (updatedOrder.id === currentOrder.id) {
            Cookies.set("trackedOrder", JSON.stringify(updatedOrder));
            dispatch(setCurrentOrder(updatedOrder));
            updateTrack(updatedOrder);
          }
        },
        error: (error) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in sb) {
          sb.unsubscribe();
        }
      };
    }
  }, []);

  const handleTrack = () => {
    navigate("/trackOrder", { state: { Order: order } });
  };

  return (
    <>
      <Grid container className="OrderHome" onClick={handleTrack}>
        <Grid container alignContent={"center"}  item xs={2.5}>
        <img alt="waiter" src={cookingIcon} 
             style={{
              width: 60,
              height: 60,
              cursor: "pointer",
              color:"black"
            }}/>
        </Grid>
     
        <Grid container padding={".5rem"} item xs={9.5}>
        <Grid container justifyContent={"start"} item xs={12} >
        <Typography sx={{ fontFamily: `etihadBold${i18n.language}`,letterSpacing: 0.88,wordWrap: 'break-word'}}>{t("OrderIs")} {t(status)}</Typography>
              <button style={{marginLeft:"auto",marginRight:"auto"}}  className="OrderHomeTrackBtn"  onClick={handleTrack}>
              {t("orderTrack")}             
              </button>
        </Grid>

        <Grid item container justifyContent={"start"} justifyItems={"start"} xs={12}>
          {order?.orderedItems.map((item,index) => {
            return (
                <Box key={index}>
                  <Typography align="left" display={"inline"}  sx={{color: 'rgba(0,0,0,0.70)',fontFamily: `etihadBook${i18n.language}`,fontWeight: '600'}} key={item.id}>
                  {capitalizeSentence(new Map(JSON.parse(item.nameMultiLanguages))?.get(`${i18n.language}-${"name"}`)??item.name)}
                  </Typography>
                  <Typography align="left"display={"inline"} style={{padding:".5rem"}} sx={{ color: 'rgba(0,0,0,0.70)',fontFamily: `etihadBook${i18n.language}`,fontWeight: '600'}} key={item.id}>
                    {i18n.language==="Arabic"?"":"x"}
                  {numberTranslation(item.quantity,i18n.language)}
                  {i18n.language!=="Arabic"?"":"x"}
                    </Typography>
                </Box>
            );
          })}
        </Grid>
        </Grid>
        <Grid item xs={12} className="OrderHomeFooter">
        <Typography style={{fontSize:12 ,textAlign: 'center', color: 'black',fontFamily: `etihadBook${i18n.language}`, fontWeight: '600', letterSpacing: 0.80, wordWrap: 'break-word'}}>
        {t("OrderID")}: #{order.id.split("-")[0]}
        </Typography>
        <Typography style={{ fontSize:12,textAlign: 'center', color: '#747171',  fontFamily: `etihadBook${i18n.language}`, fontWeight: '600', letterSpacing: 0.80, wordWrap: 'break-word'}}>
        {order.orderedItems.length} {t("items")}{" "}
            {order.totalAmount > 0 ? `| ${order.totalAmount} AED` : ""}
          </Typography>
          <Typography style={{fontSize:12,color: 'rgba(115.74, 113.33, 113.33, 0.73)', fontFamily: `etihadBook${i18n.language}`, fontWeight: '600', letterSpacing: 0.80, wordWrap: 'break-word'}}>
          {t("Placed at")} {numberTranslation(formatTime(new Date(order.createdAt)),i18n.language)}
            </Typography>
        </Grid>
      </Grid>
    </>
  );
}
